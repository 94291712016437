<template>
    <div class="error-page">
        <div class="error-area stars">
            <!-- Error Content -->
            <div class="error-content d-flex flex-column justify-content-center align-items-center text-center">
                <img class="error-thumb" src="assets/img/404.svg" alt="">
                <a href="/" class="btn btn-bordered-white mt-4">Regresar Al Inicio</a>
            </div>
            <!-- Error Objects -->
            <div class="error-objects">
                <img class="thumb-1" src="assets/img/rocket.svg" alt="">
                <!-- Earth Moon -->
                <div class="earth-moon">
                    <img class="thumb-2" src="assets/img/earth.svg" alt="">
                    <img class="thumb-3" src="assets/img/moon.svg" alt="">
                </div>
                <!-- Astronaut -->
                <div class="astronaut">
                    <img class="thumb-4" src="assets/img/astronaut.svg" alt="">
                </div>
            </div>
            <!-- Glowing Stars -->
            <div class="glowing-stars">
                <div class="star"></div>
                <div class="star"></div>
                <div class="star"></div>
                <div class="star"></div>
                <div class="star"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>